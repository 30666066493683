/**
 * > When using the esBuild API, all `process.env.NODE_ENV` expressions
 * > are automatically defined to `"production"` if all minification
 * > options are enabled and `"development"` otherwise. This only happens
 * > if `process`, `process.env`, and `process.env.NODE_ENV` are not already
 * > defined. This substitution is necessary to avoid code crashing instantly
 * > (since `process` is a Node API, not a web API).
 * > — https://esbuild.github.io/api/#platform
 */

const NODE_ENV = process.env.NODE_ENV
const IS_DESKTOP = typeof window.orientation === 'undefined'

// Main environment variables
const ENV = Object.freeze({
    // Node environment
    NAME: NODE_ENV,
    IS_PROD: NODE_ENV === 'production',
    IS_DEV: NODE_ENV === 'development',

    // Device
    IS_DESKTOP,
    IS_MOBILE: !IS_DESKTOP,
})

// Main CSS classes used within the project
const CSS_CLASS = Object.freeze({
    LOADING: 'is-loading',
    LOADED: 'is-loaded',
    READY: 'is-ready',
    FONTS_LOADED: 'fonts-loaded',
    LAZY_CONTAINER: 'c-lazy',
    LAZY_LOADED: '-lazy-loaded',
    // ...
})

// Custom js events
const CUSTOM_EVENT = Object.freeze({
    RESIZE_END: 'loco.resizeEnd',
    // ...
})

// Fonts parameters
const FONT = Object.freeze({
    EAGER: [
        // { family: 'Gambarino-Regular', style: 'normal', weight: 400 },
        // { family: 'borna-medium-webfont', style: 'normal', weight: 500 },
        { family: 'bariol_serif_regular_italic-webfont', style: 'normal', weight: 400 },
        // { family: 'Literata-ExtraLight', style: 'normal', weight: 200 },
        // { family: 'Panchang-Bold', style: 'normal', weight: 700 },
        // { family: 'Supreme-Regular', style: 'normal', weight: 400 },
        { family: 'Manrope', style: 'normal', weight: 400 },
        // { family: 'Manrope', style: 'normal', weight: 700 }, // use forced to save in size
        // if you comment one line here you also need to comment out a line in config.scss
    ],
})

export {
    ENV,
    CSS_CLASS,
    CUSTOM_EVENT,
    FONT,
}
