import { module } from 'modujs';
import { html } from '../utils/environment';
import modularLoad from 'modularload';

const NAV = {
    BLACK: `#0c0a0a`,
    WHITE: `#eee7e7`,
}

export default class extends module {
    constructor(m) {
        super(m);

        this.newTheme;
    }

    setTheme() {
        // try {
            if (localStorage.theme === 'dark' || (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
                html.setAttribute('data-theme', 'dark')
                window.localStorage.setItem('theme', 'dark')
                document.querySelector('meta[name="theme-color"]').setAttribute('content', '#0c0a0a')
            } else {
                html.setAttribute('data-theme', 'light')
                window.localStorage.setItem('theme', 'light')
                document.querySelector('meta[name="theme-color"]').setAttribute('content', '#eee7e7')
            }
        // } catch (_) {}
    }

    init() {

        this.setTheme()

        const load = new modularLoad({
            enterDelay: 600,
            // loadedDelay: 600,
            transitions: {}
        });

        load.on('loaded', (transition, oldContainer, newContainer) => {
            this.setTheme()
            this.call('destroy', oldContainer, 'app');
            this.call('update', newContainer, 'app');
        });

        load.on('loading', (transition, oldContainer) => {
            // this.call('close', null, 'Nav')
            html.classList.remove('has-nav-open');
        });
    }

    goTo(options) {
        this.load.goTo(options.url, options.transitionName);
    }
}
