import { module } from 'modujs';
import { html } from '../utils/environment';
import { lazyLoadImage } from '../utils/image';
import LocomotiveScroll from 'locomotive-scroll';

export default class extends module {
    constructor(m) {
        super(m);

        this.scrollY;
    }

    init() {
        this.scroll = new LocomotiveScroll({
            el: this.el,
            smooth: true,
        });

        this.scroll.on('call', (func, way, obj, id) => {
            // Using modularJS
            this.call(func[0], { way, obj }, func[1], func[2]);
        });

        this.scroll.on('scroll', (args) => {

            if (this.scrollY > args.scroll.y) {
                html.classList.add("is-scrolling-up")
            } else {
                html.classList.remove("is-scrolling-up")
            }

            this.scrollY = args.scroll.y

            if (args.scroll.y > 50) {
                html.classList.add("has-scrolled");
            } else {
                html.classList.remove("has-scrolled");
            }
        })
    }

    /**
     * Lazy load the related image.
     *
     * @see ../utils/image.js
     *
     * It is recommended to wrap your `<img>` into an element with the
     * CSS class name `.c-lazy`. The CSS class name modifier `.-lazy-loaded`
     * will be applied on both the image and the parent wrapper.
     *
     * ```html
     * <div class="c-lazy o-ratio u-4:3">
     *     <img data-scroll data-scroll-call="lazyLoad, Scroll, main" data-src="http://picsum.photos/640/480?v=1" alt="" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" />
     * </div>
     * ```
     *
     * @param {LocomotiveScroll} args - The Locomotive Scroll instance.
     */
    lazyLoad(args) {
        lazyLoadImage(args.obj.el, null, () => {
            //callback
        })
    }

    update() {
        this.scroll.update();
    }

    scrollTo({$target, options={}}) {

        if($target) {
            // window.location.hash = `#${id}`
            this.scroll.scrollTo($target, options);
        }
    }

    destroy() {
        this.scroll.destroy();
        html.classList.remove("is-scrolling-up");
        html.classList.remove("has-scrolled");
    }
}
