import { module } from 'modujs';
import { html } from '../utils/environment'
import * as focusTrap from 'focus-trap';

const CLASS = {
    OPEN: `has-nav-open`,
}

const NAV = {
    BLACK: `#0c0a0a`,
    WHITE: `#eee7e7`,
    BG:    `#e01535`,
}

export default class extends module {
    constructor(m) {
        super(m);

        this.$header = this.$('header')[0]
        this.$menuOpen = this.$('menu-open')[0]
        this.$menuClose = this.$('menu-close')[0]
        this.$menu = this.$('menu')[0]

        this.newTheme;

        this.events = {
            click: {
                'menu-open': 'open',
                'menu-close': 'close',
                'invert-theme': 'invertTheme',
            }
        }
    }

    init(){
        this.closeBind = (e) => {
             if (e.key === "Escape") {
                this.close();
            }
        }
        document.addEventListener('keyup', this.closeBind)

        this.focusTrap = focusTrap.createFocusTrap([this.$menu]);
    }

    checkTheme() {
        this.newTheme = html.getAttribute('data-theme');

        if (this.newTheme == 'dark') {
            html.querySelector("meta[name=theme-color]").content = NAV.BLACK
        } else {
            html.querySelector("meta[name=theme-color]").content = NAV.WHITE
        }
    }

    invertTheme() {
        this.newTheme = html.getAttribute('data-theme') == 'dark' ? 'light' : 'dark';
        html.setAttribute('data-theme', this.newTheme)
        // console.log("newTheme", this.newTheme);
        if (this.newTheme == 'dark') {
            window.localStorage.setItem('theme', 'dark')
            html.setAttribute('data-theme', 'dark')
        } else {
            window.localStorage.setItem('theme', 'light')
            html.setAttribute('data-theme', 'light')
        }
    }

    // toggle() {
    //     html.classList.contains(CLASS.OPEN) ? this.close() : this.open()
    // }

    open() {
        html.classList.add(CLASS.OPEN)

        this.$menuOpen.setAttribute('aria-expanded', true);

        this.timeout = setTimeout(() => {
            html.querySelector("meta[name=theme-color]").content = NAV.BG
        }, 300)

        this.focusTrap.activate()
    }

    close() {
        html.classList.remove(CLASS.OPEN)

        this.$menuOpen.setAttribute('aria-expanded', false);

        this.timeout = setTimeout(() => {
            this.checkTheme()
        }, 300)

        this.focusTrap.deactivate()
    }

    destroy() {
        document.removeEventListener('keyup', this.closeBind)

        this.focusTrap.deactivate()
    }
}
