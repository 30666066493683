import { module } from 'modujs';

export default class Accordion extends module {

    static get ACTIVE_CLASS() {
        return 'is-open'
    }

    constructor(m) {
        super(m);

        // DOM Elements
        this.$container = this.$('container')[0]
        this.$content = this.$('content')[0]
        this.$toggler = this.$('toggler')[0]

        // Opened state if data-accordion-opened
        this.isOpen = this.getData('opened') !== null

        // Events
        this.events = {
            click: {
                'toggler': 'toggle'
            },
        }
    }

    init() {

        // Set sizes
        this.resize()

        // Default opened
        if(this.isOpen) {
            this.open()
        }

        // Key events
        this.$toggler.addEventListener('keydown', this.onKeydown = e => {
            if(e.code === 'Space' || e.code === 'Enter') {
                this.toggle()
            }
        })

        // Add window event
        window.addEventListener('resizeEnd', this.onResize = () => this.resize())
    }

    toggle() {

        if(this.isOpen) {
            this.close()
        } else {
            this.open()
        }
    }

    open() {

        // // Callback function
        // this.call('close', this.id, 'Accordion')

        this.el.classList.add(Accordion.ACTIVE_CLASS)
        this.isOpen = true

        const options = {
            offset: -96 // Header bar
        }
        this.call('scrollTo', { $target: this.el, options }, 'Scroll', 'main')

        // Clear close callback
        if (this.closeTimeout) {
            clearTimeout(this.closeTimeout);
        }

        // Set open callback
        this.openTimeout = setTimeout(() => {

            // Callback function
            this.call('update', null, 'Scroll')

        }, this.duration)
    }

    close() {

        if(!this.isOpen) {
            return
        }

        this.el.classList.remove(Accordion.ACTIVE_CLASS)
        this.isOpen = false

        // Clear open callback
        if (this.openTimeout) {
            clearTimeout(this.openTimeout);
        }

        // Set close callback
        this.closeTimeout = setTimeout(() => {
            console.log('closed')

            // Callback function
            this.call('update', null, 'Scroll')

        }, this.duration);
    }

    resize() {

        // Set content height
        const height = this.$content.offsetHeight
        this.el.style.setProperty('--accordion-container-height', `${height}px`)

        // Get
        const duration = window.getComputedStyle(this.el).getPropertyValue('--accordion-duration');
        this.$container.style.setProperty('--accordion-duration', duration);
        this.duration = 1000 * parseInt(duration, 10);

        setTimeout(() => {
            // Callback function
            this.call('update', null, 'Scroll')

        }, this.duration);
    }

    destroy() {

        // Remove window event
        window.removeEventListener('resizeEnd', this.onResize)
    }
}
